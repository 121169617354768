// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  identityPoolId: 'ca-central-1:e8d130af-74da-4bbb-90d2-f9edb1d986b2',
  cognitoClientId: '1id8f8csouistejaib9q0vmgn4',
  cognitoPoolId: 'ca-central-1_LdW7vmhyG',
  bucketName: 'es-content-01',
  region: 'ca-central-1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
