import { Component } from '@angular/core';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Patients',
      url: '/patients',
      icon: 'search'
    },
    {
      title: 'Bookings',
      url: '/calendar',
      icon: 'calendar'
    },
    {
      title: 'Schedules',
      url: '/schedules',
      icon: 'time'
    },
    {
      title: 'Products',
      url: '/inventory',
      icon: 'pricetags'
    },
    // {
    //   title: 'Lenses',
    //   url: '/inventory-lenses',
    //   icon: 'layers'
    // },

    {
      title: 'Quick Labels',
      url: '/inventory-quicklabels',
      icon: 'hourglass'
    },
    {
      title: 'Product Labels',
      url: '/inventory-labels',
      icon: 'print'
    },
    {
      title: 'Inventory Scans',
      url: '/inventory-scans',
      icon: 'qr-code'
    },
    {
      title: 'Reconciliation',
      url: '/reconciliation',
      icon: 'archive'
    },
    {
      title: 'Templates',
      url: '/templates',
      icon: 'document-lock'
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'people'
    },
    {
      title: 'Locations',
      url: '/locations',
      icon: 'location'
    },
    {
      title: 'Logout',
      url: '/login',
      icon: 'log-out'
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(public app: AppService) {}
}
